import {useEffect, useMemo, useRef, useCallback, useState} from 'react';
import {useJobContext, useAppContext, useNotificationContext} from 'contexts';
import {useGetDeliveryAddressQuery} from 'components/customer/Settings/store/settingsApi';
import {useGetDepotsQuery} from 'store/customer/depotApi';
import {useAppDispatch} from 'store/customer';
import {
    invalidateJobCost,
    useGetJobCostQuery,
    useJobSaveMutation,
} from 'components/customer/Job/store/jobApi';
import {selectedDepot} from 'store/customer/depotSlice';
import {DispatchMethod, useJobForm} from 'hooks';
import {genericMessageHandler} from 'shared/helpers';
import {PartialJob} from 'shared/types/PartialJob';
import {DeliveryAddress} from 'shared/types/DeliveryAddress';

interface FormValues {
    job_name?: string;
    job_delivery_name?: string;
    job_contact_number?: string;
    job_description?: string;
    job_dispatch?: number | string;
    depot_id?: number | string;
    job_city?: string;
    job_suburb?: string;
    job_postcode?: number | string;
    job_country_State?: number;
    job_client_id?: string | number;
    job_address?: string;
    is_dispatch_method_changed?: number;
}

interface DispatchMethod {
    value: string | number;
    id: string;
    label: string;
    selectedImage: string;
    image: string;
    display: number;
    selectable: boolean;
}

interface SelectedItem {
    id: number | string;
    label: string;
}

interface selectedOptionProp {
    selected_dispatch_method?: SelectedItem;
    selected_depot?: SelectedItem;
    selected_address?: SelectedItem & {delivery_address?: DeliveryAddress};
    toggle_change?: boolean;
}

export const useJobVariationFreightCost = () => {
    const {job} = useJobContext() as PartialJob;
    const {userProfile} = useAppContext();
    const {dispatchMethods} = useJobForm(job?.id);
    const {data: deliveryAddressList} = useGetDeliveryAddressQuery();
    const {data: depotList} = useGetDepotsQuery();
    const {notify} = useNotificationContext();
    const dispatch = useAppDispatch();
    const {data: cost, isFetching: costFetching} = useGetJobCostQuery(
        {jobId: job.id},
        {
            skip: typeof job?.id === 'undefined',
        }
    );
    const [selectedOption, setSelectedOption] = useState<selectedOptionProp>({
        toggle_change: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const getDefaultValues = (job: PartialJob['job']): FormValues => ({
        job_name: job.name,
        job_delivery_name: job.endCustomerName,
        job_contact_number: job.endContactNumber,
        job_description: job.description,
        job_client_id: job.clientId,
    });
    const resetValuesToDefault = () => {
        valuesRef.current = getDefaultValues(job);
    };
    const valuesRef = useRef<FormValues>(getDefaultValues(job));
    const [saveJob] = useJobSaveMutation();

    useEffect(() => {
        valuesRef.current.job_dispatch = job.dispatchMethod;
    }, [job.dispatchMethod]);

    const dispatchMethodOptions = useMemo(() => {
        return dispatchMethods.filter((d) => {
            if (DispatchMethod.FREIGHT_TO_CLIENT_ADDRESS == d.value) {
                d.display = job?.clientId == -1 ? 0 : 1;
            }

            return d.selectable && d.display == 1;
        });
    }, [dispatchMethods, job]);

    const handlChangedDispatchMethod = useCallback(
        (dispatch: DispatchMethod) => (event: React.MouseEvent) => {
            event.preventDefault();

            setSelectedOption((prevId) => ({
                ...prevId,
                selected_dispatch_method: {
                    id: dispatch.value,
                    label: dispatch.label,
                },
            }));
        },
        []
    );

    const toggleChange = useCallback(() => {
        setSelectedOption((prevId) => ({
            ...prevId,
            toggle_change: !prevId.toggle_change,
        }));
    }, [selectedOption.toggle_change]);

    useEffect(() => {
        if (selectedOption?.selected_dispatch_method && dispatchMethodOptions) {
            const dispatchOption = dispatchMethodOptions.find(
                (dispatch) =>
                    dispatch.value ==
                    selectedOption?.selected_dispatch_method?.id
            );

            // auto select dispatch methods
            setSelectedOption((prevId) => ({
                ...prevId,
                selected_dispatch_method: {
                    id: dispatchOption?.value || dispatchMethodOptions[0].value,
                    label:
                        dispatchOption?.label || dispatchMethodOptions[0].label,
                },
            }));
        }
    }, [dispatchMethodOptions]);

    const handleChangeDepot = useCallback(
        (eventKey: string | null) => {
            const depot = depotList?.depots?.find(
                (depot) => depot.id == parseInt(eventKey)
            );
            dispatch(selectedDepot(depot.id));

            setSelectedOption((prevId) => ({
                ...prevId,
                selected_depot: {
                    id: depot.id,
                    label: depot.depot_name,
                },
            }));
        },
        [selectedOption]
    );

    const submitJob = useCallback(async () => {
        const depot = selectedOption?.selected_depot;
        const dispatchMethod = selectedOption?.selected_dispatch_method;
        const address = selectedOption?.selected_address?.delivery_address;
        const street = Array.isArray(address?.street)
            ? address.street.join(' ')
            : address?.street;

        const newValues: FormValues = {
            ...valuesRef.current,
            depot_id: depot?.id || '',
            job_dispatch: dispatchMethod?.id,
            job_city: address?.city || '',
            job_suburb: address?.suburb,
            job_country_State: address?.state,
            job_address: street,
            job_postcode: address?.postcode,
            is_dispatch_method_changed:
                job.depotId != depot.id ||
                job.dispatchMethod != dispatchMethod?.id ||
                !checkAddress(address)
                    ? 1
                    : 0,
        };

        const formData = new FormData();
        for (const [key, value] of Object.entries(newValues)) {
            if (value !== undefined && value !== null) {
                formData.append(key, String(value));
            }
        }

        setIsLoading(true);
        try {
            await saveJob({
                data: formData,
                jobId: job.displayId,
            });
            dispatch(invalidateJobCost());

            setSelectedOption((prevId) => ({
                ...prevId,
                toggle_change: !prevId.toggle_change,
            }));
        } catch (error) {
            genericMessageHandler(notify, {
                message: 'Something went wrong while updating your job',
            });
        } finally {
            setIsLoading(false);
        }

        resetValuesToDefault();
    }, [selectedOption]);

    const handleChangeAddress = useCallback(
        (address: DeliveryAddress) => (event: React.MouseEvent) => {
            event.preventDefault();
            const itemStreet = getItemStreet(address);

            setSelectedOption((prevId) => ({
                ...prevId,
                selected_address: {
                    id: `${address?.suburb} ${address?.city} ${address?.postcode}`,
                    label: `${itemStreet} ${address?.suburb}`,
                    delivery_address: address,
                },
            }));
        },
        []
    );

    const isSelectedAddressSameToJobAddress = useCallback(() => {
        const isAddressValid = checkAddress(
            selectedOption?.selected_address?.delivery_address
        );
        const isDispatchMethodValid =
            selectedOption?.selected_dispatch_method?.id == job.dispatchMethod;
        const isDepotValid =
            !job.depotId || job.depotId == selectedOption?.selected_depot?.id;

        return isAddressValid && isDispatchMethodValid && isDepotValid;
    }, [job, selectedOption]);

    const getItemStreet = (item: DeliveryAddress) => {
        let itemStreet = '';
        if (item?.street) {
            if (Array.isArray(item.street)) {
                itemStreet = item.street.join(' ');
            } else {
                itemStreet = item.street;
            }
        }

        return itemStreet;
    };

    const checkAddress = (item: DeliveryAddress) => {
        if (item && job) {
            const itemStreet = getItemStreet(item);
            const response =
                (!job.address || job.address == itemStreet) &&
                (!job.city || job.city == item.city) &&
                (!job.suburb || job.suburb == item.suburb) &&
                (!job.postcode || job.postcode == item.postcode);

            return response;
        }
    };

    const allAddresses = useMemo(() => {
        if (deliveryAddressList) {
            const address = deliveryAddressList?.find((i) => checkAddress(i));
            if (!address) {
                const customAddress: DeliveryAddress = {
                    city: job.city ? job.city : '',
                    street: job.address,
                    suburb: job.suburb,
                    postcode: job.postcode,
                    state: job.state ? parseInt(job.state) : null,
                };

                const updatedAddresses = [
                    ...deliveryAddressList,
                    customAddress,
                ];

                // if new user and no job addresses and no delivery Address , look at the user account
                if (
                    updatedAddresses.length === 1 &&
                    !updatedAddresses[0].city &&
                    !updatedAddresses[0].street &&
                    !updatedAddresses[0].suburb &&
                    !updatedAddresses[0].postcode
                ) {
                    return [
                        {
                            city: '',
                            street: `${userProfile.address} ${userProfile.address2}`,
                            suburb: userProfile.suburb,
                            postcode: userProfile.postcode,
                            state: userProfile.addressState,
                        },
                    ];
                }

                return updatedAddresses;
            }

            return deliveryAddressList;
        }

        return [];
    }, [deliveryAddressList]);

    useEffect(() => {
        if (job) {
            const depot = depotList?.depots?.find(
                (depot) => depot.id == job.depotId
            );

            const dispatch = dispatchMethodOptions?.find(
                (i) => i.value == job.dispatchMethod
            );

            const address = allAddresses?.find((i) => checkAddress(i));
            let itemStreet = '';
            if (address) {
                itemStreet = getItemStreet(address);
            }

            setSelectedOption((prevId) => ({
                ...prevId,
                selected_depot: {
                    id: depot?.id,
                    label: depot?.depot_name,
                },
                selected_dispatch_method: {
                    id: dispatch?.value || dispatchMethodOptions[0].value,
                    label: dispatch?.label || dispatchMethodOptions[0].label,
                },
                selected_address: {
                    id: `${address?.suburb} ${address?.city} ${address?.postcode}`,
                    label: `${itemStreet} ${address?.suburb}`,
                    delivery_address: address,
                },
            }));
        }
    }, [job, depotList, allAddresses]);

    return {
        cost,
        depotList,
        costFetching,
        getItemStreet,
        handlChangedDispatchMethod,
        toggleChange,
        handleChangeDepot,
        submitJob,
        handleChangeAddress,
        isSelectedAddressSameToJobAddress,
        selectedOption,
        isLoading,
        dispatchMethodOptions,
        allAddresses,
        userProfile,
    };
};
