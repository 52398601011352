import {useState, useEffect, useMemo} from 'react';
import {useAppContext, useJobContext, useNotificationContext} from 'contexts';
import {getNotesForCustomAddress} from 'service';
import {genericMessageHandler, usePromise} from 'shared/helpers';
import {useNavigate, useParams} from 'react-router-dom';
import {
    useGetJobStatusStatisticsQuery,
    useJobSaveMutation,
    useLazyGetJobQuery,
} from 'components/customer/Job/store/jobApi';
import {JobRedirectEnum} from 'components/manufacturer/Preferences/entity/Preferences';
import {BaseConfig} from 'config';
import excel from 'shared/Excel';
import {JobStatus, JobStatusFilter} from 'shared/JobStatus';

export const DispatchMethod = {
    PICKUP: 0,
    FREIGHT_DELIVERY_ADDRESS: 2,
    FREIGHT_CUSTOM_ADDRESS: 1,
    FREIGHT_TO_CLIENT_ADDRESS: 3,
};

const config = BaseConfig['USER_CUSTOMER'];
const CreateJobRedirect = {
    [JobRedirectEnum.PRODUCT]: {
        path: 'product',
        isVisible: config.jobMenu.find((menu) => menu.id === 'add_product')
            .isVisible,
    },
    [JobRedirectEnum.FAVOURITES]: {
        path: 'favourites',
        isVisible: config.jobMenu.find(
            (menu) => menu.id === 'favourite_products'
        ).isVisible,
    },
    [JobRedirectEnum.SUNDRIES]: {
        path: 'hardware',
        isVisible: config.jobMenu.find((menu) => menu.id === 'sundry')
            .isVisible,
    },
    [JobRedirectEnum.LAYOUT]: {
        path: 'layout',
        isVisible: config.jobMenu.find((menu) => menu.id === 'tdld').isVisible,
    },
    [JobRedirectEnum.DETAILED_CART]: {
        path: 'dashboard',
        isVisible: true,
    },
    [JobRedirectEnum.QFP]: {
        path: 'quick-flat-product',
        isVisible: config.jobMenu.find((menu) => menu.id === 'qfp').isVisible,
    },
};

const getCreateJobRedirect = (userProfile, jobId, roomId) => {
    const {
        createJobLandingPage,
        isBenchtopAvailable,
        isBTDAvailable,
        isAddProductAvailable,
    } = userProfile;

    if (createJobLandingPage === JobRedirectEnum.BENCHTOP) {
        // only one will be active for benchtop, see user api for details
        if (isBenchtopAvailable) {
            return `/v2/job/${jobId}/benchtop-module`;
        } else if (isBTDAvailable && roomId !== '') {
            return `/v2/job/${jobId}/room/${roomId}/benchtop-designer`;
        }
    }

    if (roomId === '') {
        // return to dashboard by default if no room id
        return `/v2/job/${jobId}/dashboard`;
    }

    if (
        createJobLandingPage !== JobRedirectEnum.BENCHTOP &&
        createJobLandingPage in JobRedirectEnum
    ) {
        const {path, isVisible} = CreateJobRedirect[createJobLandingPage];
        const moduleAvailable =
            typeof isVisible !== 'boolean'
                ? excel.calculate(isVisible, userProfile)
                : isVisible;
        if (
            [JobRedirectEnum.SUNDRIES, JobRedirectEnum.DETAILED_CART].includes(
                createJobLandingPage
            ) &&
            moduleAvailable
        ) {
            return `/v2/job/${jobId}/${path}`;
        }

        if (moduleAvailable) {
            return `/v2/job/${jobId}/room/${roomId}/${path}`;
        }
    }

    if (!isAddProductAvailable) {
        return `/v2/job/${jobId}/dashboard`;
    }

    // default to product if available
    return `/v2/job/${jobId}/room/${roomId}/product`;
};

export const useFreightNotes = (
    dispatchMethod = DispatchMethod.FREIGHT_CUSTOM_ADDRESS
) => {
    const {notify} = useNotificationContext();
    const [type, setType] = useState(dispatchMethod);
    const [notes, setNotes] = useState([]);

    useEffect(() => {
        const promise = getNotesForCustomAddress(true);

        return usePromise(
            ([notes]) => {
                setNotes(notes);
            },
            [promise],
            (error) => {
                genericMessageHandler(notify, error);
            }
        );
    }, [type]);

    return {setType, notes};
};

export const useJobFormSubmitHandler = (jobId) => {
    const {notify} = useNotificationContext();
    const {userProfile} = useAppContext();
    const navigate = useNavigate();

    const [saveJob, {isLoading}] = useJobSaveMutation();
    const [getJobDetails] = useLazyGetJobQuery();
    const {room} = useJobContext();

    const fieldMap = {
        jobName: 'job_name',
        jobRefCode: 'job_delivery_name',
        contactNumber: 'job_contact_number',
        description: 'job_description',
        dispatch: 'job_dispatch',
        address: 'job_address',
        city: 'job_city',
        street: 'job_address',
        suburb: 'job_suburb',
        postcode: 'job_postcode',
        state: 'job_country_State',
        client: 'job_client_id',
        files: 'job_attachments',
        depotId: 'depot_id',
        set_default_address: 'set_default_address',
        save_address: 'save_address',
        requestedDeliveryDate: 'requested_delivery_date',
    };

    const formData = new FormData();

    const jobFormSubmitHandler = async (values) => {
        Object.keys(fieldMap).forEach((key) => {
            if (key === 'files') {
                values[key].forEach((file) => {
                    formData.append(`${fieldMap[key]}[]`, file);
                });

                return;
            }
            formData.append(fieldMap[key], values[key]);
        });

        try {
            const {data: response} = await saveJob({data: formData, jobId});

            genericMessageHandler(
                notify,
                {message: response.messages},
                'success'
            );

            let roomId = room?.id;

            let updatedJobDetails;
            if (jobId === undefined) {
                const {data} = await getJobDetails({
                    jobId: parseInt(response.jobId),
                });
                updatedJobDetails = data;
            }

            roomId = updatedJobDetails?.rooms?.[0]?.id || roomId;

            if ('displayId' in response) {
                navigate(
                    getCreateJobRedirect(
                        userProfile,
                        response.displayId,
                        roomId
                    )
                );
            }
        } catch (error) {
            genericMessageHandler(notify, [
                'Something went wrong while creating your job. Please trying logging out and logging back in.',
                error.message,
            ]);
        }
    };

    return {
        jobFormSubmitHandler,
        loadingSave: isLoading,
    };
};

export const useJobListData = () => {
    const {status} = useParams();
    const [sortBy, setSortBy] = useState([9]);
    const {userProfile} = useAppContext();
    const [orderBy, setOrderBy] = useState('desc');
    const [order, setOrder] = useState({
        fields: [9],
        order: 'desc',
    });

    const {data: jobStatistics, isLoading} = useGetJobStatusStatisticsQuery();

    const allJobStatus = useMemo(() => {
        return [
            {id: 'all', url: 'all', text: 'All'},
            ...JobStatusFilter.filter(
                (status) => !jobStatistics || jobStatistics?.includes(status.id)
            ).map((filter) => {
                const filterFound = userProfile?.jobStatuses?.find(
                    (jobStatus) => filter.status === jobStatus.status
                );
                return {
                    ...filter,
                    text:
                        !!filterFound && filterFound?.custom_label
                            ? filterFound.custom_label
                            : JobStatus[filter.status],
                };
            }),
        ];
    }, [JobStatusFilter, jobStatistics, userProfile?.jobStatuses]);

    const selectedStatus = useMemo(() => {
        let selectedStatusOption = allJobStatus.find(
            (statusOption) => statusOption.url == status
        );

        if (!selectedStatusOption) {
            selectedStatusOption = allJobStatus[0];
        }

        return selectedStatusOption;
    }, [status, allJobStatus]);

    useEffect(() => {
        const newOrder = {...order};
        newOrder.fields = sortBy;
        newOrder.order = orderBy;
        setOrder(newOrder);
    }, [sortBy, orderBy]);

    return {
        allJobStatus,
        sortBy,
        orderBy,
        setOrderBy,
        setSortBy,
        selectedStatus,
        userProfile,
        isLoading,
    };
};
