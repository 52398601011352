import {useJobContext, useKDMaxContext, useNotificationContext} from 'contexts';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    deleteImportedProduct,
    getProductByCode,
    updateImportedProduct,
    updateCode,
    getProductConfig,
} from 'service';
import {genericMessageHandler, formatDimensionString} from 'shared/helpers';
import {useAddProductManually} from 'shared/helpers/addProductManually';
import {ProductSearch} from 'components';
import {cloneDeep} from 'lodash';
import {useAppDispatch} from 'store/customer';
import {invalidateProductAndCost} from 'components/customer/Product/store/productApi';

export const useKDMaxProduct = (product) => {
    const navigate = useNavigate();
    const {room, refresh} = useJobContext(product);
    const {notify} = useNotificationContext();
    const {setCabinets, showDialog, hideDialog} = useKDMaxContext();
    const [cabinet, setCabinet] = useState(product);
    const addProductManually = useAddProductManually();

    const {jobId} = useParams();
    const [loader, setLoader] = useState(false);

    const editCabinet = async () => {
        const dbCabinet = await getProductByCode(cabinet.code);

        if (cabinet) {
            navigate(
                `/v2/job/${jobId}/room/${room.id}/product?product=${dbCabinet.id}&height=${cabinet.height}&width=${cabinet.width}&depth=${cabinet.depth}&importid=${cabinet.id}&goto=kdmax-import`
            );
        }
    };

    const dispatch = useAppDispatch();

    const addToCartHandler = async (quickProduct) => {
        const options = {
            hasError: !cabinet.height || !cabinet.depth || !cabinet.width,
            height: !cabinet.height ? room.baseHeight : cabinet.height,
            depth: !cabinet.depth ? room.baseDepth : cabinet.depth,
            width: cabinet.width || 0,
        };

        await updateCode(cabinet.id, quickProduct, options);
        const productConfig = await getProductConfig(quickProduct.id);
        const dimensionString = formatDimensionString(productConfig, options);
        const updatedCabinet = cloneDeep(cabinet);
        updatedCabinet.code = quickProduct.code;
        updatedCabinet.image = quickProduct.changedImage
            ? '/' + quickProduct.changedImage
            : '/uploads/cabinet_images/' + quickProduct.image;
        updatedCabinet.isValidated = !options.hasError;
        updatedCabinet.isAvailable = true;
        updatedCabinet.type = quickProduct.id;
        updatedCabinet.dimensionString = dimensionString;
        updatedCabinet.height = options.height;
        updatedCabinet.depth = options.depth;
        updatedCabinet.width = options.width;
        updatedCabinet.hasError = options.hasError;
        setCabinet(updatedCabinet);
        hideDialog();
    };

    const showProducts = () => {
        showDialog({
            modalDialogClass: 'modal-dialog-kdmax',
            title: 'Products',
            message: (
                <section className="sidebar-content layout-menu">
                    <ProductSearch
                        addButtonText="Select Product"
                        callBack={addToCartHandler}
                        embedded={true}
                    />
                </section>
            ),
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    name: 'Cancel',
                    show: true,
                    action: () => {
                        navigate(location.pathname);
                        hideDialog();
                    },
                },
            ],
        });
    };

    const deleteCabinet = () => {
        showDialog({
            title: 'KD Max Import',
            message: 'Are you sure, you want to delete this product?',
            yes: async () => {
                setLoader(true);
                await deleteImportedProduct(cabinet.id);
                setCabinets({
                    type: 'delete',
                    payload: cabinet.id,
                });
                setLoader(false);
            },
        });
    };

    const addCabinet = async () => {
        setLoader(true);
        const dbCabinet = await getProductByCode(cabinet.code);
        const cabinetData = {};
        cabinetData.note = cabinet.name;
        cabinetData.shelves = cabinet.shelves;
        cabinetData.shelfType = cabinet.shelfType;
        cabinetData.shelfPosition = cabinet.shelfPosition;
        cabinetData.shelfOffset = cabinet.shelfOffset;
        cabinetData.shelfStyle = cabinet.shelfStyle;
        cabinetData.drawerDetails = cabinet.drawerDetails;
        cabinetData.doorDetails = cabinet.doorDetails;

        try {
            const response = await addProductManually({
                product: dbCabinet,
                room: {id: room.id},
                width: cabinet.width,
                height: cabinet.height,
                depth: cabinet.depth,
                cabinetData,
            });

            await updateImportedProduct(
                cabinet.id,
                true,
                true,
                response.response.data.id
            );

            cabinet.isAdded = true;
            cabinet.isValidated = true;

            setCabinet(cloneDeep(cabinet));
            refresh({reloadFromServer: true});

            genericMessageHandler(
                notify,
                {message: 'Product saved'},
                'success'
            );
            dispatch(invalidateProductAndCost());
            setLoader(false);
        } catch (e) {
            cabinet.isValidated = false;
            await updateImportedProduct(cabinet.id, false, false);

            setCabinet(cloneDeep(cabinet));

            setLoader(false);
        }
    };

    useEffect(() => setCabinet(product), [product]);

    return {
        loader,
        room,
        editCabinet,
        addCabinet,
        deleteCabinet,
        showProducts,
        cabinet,
    };
};
